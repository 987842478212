<template>
    <div>
      <template v-if="items.length > 0">
        <template v-if="items.length > 1">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue"
                dark
                v-bind="attrs"
                v-on="on"
                small
                v-clipboard="flag ? items[0].split(':')[0]:items[0]"
              >
                <template v-if="flag == false">
                  {{ items[0] }} ({{ items.length }})
                </template>
                <template v-else>
                  <span class="mx-2">{{ items[0].split(':')[0] }} ({{items[0].split(':')[1]}})</span>
                  <gb-flag
                      :code="items[0].split(':')[1].toString().toLowerCase()"
                      size="mini"
                      class="mx-1"
                  />
                  <span class="mx-1">({{ items.length }})</span>
                </template>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <BChip :items="items" :flag="flag" />
              </v-card-text>
            </v-card>
          </v-menu>
        </template>
        <template v-else>
          <BChip :items="items" :flag="flag" />
        </template>
      </template>
      <v-btn
        color="white"
        text
        small
        v-else
      >
        NO DATA
      </v-btn>
    </div>
  </template>
  
  <script>
  import BChip from './Chip.vue';
  import { clipboard } from 'vue-clipboards';
  export default {
    components: {
      BChip
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      flag: false,
    },
    directives: { clipboard },
  };
  </script>